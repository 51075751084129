import { FC } from "react";
import { Header } from "./common/Header";


interface TimelineEventProps {
    icon: string;
    step: string;
    date: string;
    title: string;
    description: string;
}

const TimelineEvent: FC<TimelineEventProps> = ({ icon, step, date, title, description }) => (
    <div className="relative" style={{ opacity: 1, transform: "none" }}>
        <button
            className="flex w-[100%] flex-col relative h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium rounded-large cursor-pointer transition-transform-background motion-reduce:transition-none data-[pressed=true]:scale-[0.97] tap-highlight-transparent group bg-zinc-900/90 backdrop-blur-xl border-l-4 border-y border-r border-zinc-800 overflow-hidden hover:shadow-lg hover:shadow-primary/20"
            type="button"
            role="button"
        >
            <div className="p-6 transition-all duration-300">
                <div className="flex items-center gap-4 mb-3">
                    <span className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">{step}</span>
                    <span className="text-sm font-medium text-zinc-400 bg-primary/10 px-3 py-1 rounded-full">{date}</span>
                </div>
                <h3 className="text-xl font-bold mb-2 text-white flex items-center gap-2">
                    {title} <span className="animate-bounce">{icon}</span>
                </h3>
                <p className="text-zinc-300 leading-relaxed">{description}</p>
            </div>
        </button>
    </div>
);

export const JourneyTimeline: FC = () => {
    return (
        <section id="timeline" className="py-20 relative">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-primary/5 to-transparent"></div>
            <div className="container mx-auto px-4 relative z-10" style={{ opacity: 1, transform: "none" }}>
                <div className="text-center mb-16">
                    <Header title="Journey Timeline ✨" />
                    <p className="text-zinc-400 max-w-2xl mx-auto text-lg" style={{ opacity: 1 }}>
                        Key milestones in Meena's remarkable story 🐘
                    </p>
                </div>
                <div className="max-w-3xl mx-auto space-y-8 relative before:absolute before:inset-0 before:ml-5 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-primary/50 before:via-secondary/50 before:to-primary/50">
                    <TimelineEvent
                        icon="🌿"
                        step="01"
                        date="March 2023"
                        title="Lost and Found"
                        description="Strayed from herd and discovered in a villager's pond"
                    />
                    <TimelineEvent
                        icon="🌟"
                        step="02"
                        date="April 2023"
                        title="New Beginnings"
                        description="Moved to Wildlife Rescue Center 2 (Krabak Koo)"
                    />
                    <TimelineEvent
                        icon="🌱"
                        step="03"
                        date="August 2023"
                        title="Growing Stronger"
                        description="Continued development and care at the rescue center"
                    />
                    <TimelineEvent
                        icon="✨"
                        step="04"
                        date="November 2023"
                        title="Moving Forward"
                        description="Transferred to National Elephant Institute"
                    />
                </div>
            </div>
        </section>
    );
};
