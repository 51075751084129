
interface HeaderProps {
    title: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <h2 className="text-5xl font-bold mb-6 tracking-tight">
            <span className="gradient-text">{title}</span>
        </h2>
    );
}

