import { FC } from "react";
import { Header } from "./common/Header";

interface PhotoProps {
    src: string;
    alt: string;
    title: string;
}

const Photo: FC<PhotoProps> = ({ src, alt, title }) => (
    <button
        className="flex flex-col relative h-auto text-foreground box-border bg-content1 outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium rounded-large cursor-pointer transition-transform-background motion-reduce:transition-none data-[pressed=true]:scale-[0.97] tap-highlight-transparent glass-card group hover:scale-105 transition-all duration-500 overflow-hidden"
        type="button"
        role="button"
    >
        <div className="relative aspect-[3/4] w-full">
            <img
                alt={alt}
                loading="lazy"
                decoding="async"
                data-nimg="fill"
                className="object-cover"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw"
                src={src}
                style={{ position: "absolute", height: "100%", width: "100%", inset: 0, color: "transparent" }}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-[var(--card-bg)] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <div className="absolute bottom-4 left-4 right-4 space-y-2">
                    <h3 className="text-white font-bold text-lg">{title}</h3>
                    <p className="modern-text-secondary text-sm"></p>
                </div>
            </div>
        </div>
    </button>
);

export const PhotoGallery: FC = () => {
    return (
        <section id="gallery" className="py-20 relative">
            <div className="absolute bottom-0 left-0 w-96 h-96 bg-secondary/10 rounded-full blur-3xl"></div>
            <div className="container mx-auto px-4">
                <div className="text-center mb-16 space-y-4">
                    <Header title="Photo Gallery" />
                    <p className="text-zinc-400 max-w-2xl mx-auto text-lg">
                        Capturing moments from Meena's incredible journey
                    </p>
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <Photo
                        src="/images/meena.jpg"
                        alt="First Days"
                        title="First Days"
                    />
                    <Photo
                        src="/images/meena2.jpg"
                        alt="Playing Time"
                        title="Playing Time"
                    />
                    <Photo
                        src="/images/meena3.png"
                        alt="Medical Check"
                        title="Medical Check"
                    />
                    <Photo
                        src="/images/meena4.png"
                        alt="New Home"
                        title="New Home"
                    />
                </div>
            </div>
        </section>
    );
};