import { FC, CSSProperties } from "react";
import { ChartIcon, TelegramIcon, XIcon } from "./common/Icons";
import { DEXSCREENER_LINK, TELEGRAM_LINK, X_LINK } from "./common/Config";
import { scrollToMeetMeena } from "./MeetMeena";

const NavLink: FC<{ href: string; children: React.ReactNode }> = ({ href, children }) => (
    <li className="text-medium whitespace-nowrap box-border list-none data-[active=true]:font-semibold">
        <div style={{ opacity: 1, transform: "none" }}>
            <a
                className="inline-flex items-center tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 no-underline hover:opacity-80 active:opacity-disabled text-neutral-200 hover:text-primary transition-all duration-300 text-sm font-medium relative py-2 px-1 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-0 after:left-0 after:bg-gradient-to-r after:from-primary after:to-secondary hover:after:w-full after:transition-all after:duration-300"
                href={href}
                tabIndex={0}
                role="link"
            >
                {children}
            </a>
        </div>
    </li>
);

const SocialLink: FC<{ href: string; icon: React.ReactNode }> = ({ href, icon }) => (
    <li className="text-medium whitespace-nowrap box-border list-none data-[active=true]:font-semibold">
        <div style={{ opacity: 1, transform: "none" }}>
            <a
                className="relative inline-flex items-center tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-medium text-primary no-underline hover:opacity-80 active:opacity-disabled p-3 rounded-xl bg-white/5 hover:bg-white/10 transition-all duration-300 group hover:scale-110 transform"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                role="link"
            >
                <div className="text-neutral-300 group-hover:text-primary transition-colors duration-300">
                    {icon}
                </div>
            </a>
        </div>
    </li>
);

export const TopBar: FC = () => {
    return (
        <div className="fixed w-full z-50 bg-black" style={{ transform: "none" }}>
            <nav
                className="flex z-40 w-full h-auto items-center justify-center data-[menu-open=true]:border-none sticky top-0 inset-x-0 backdrop-blur-lg data-[menu-open=true]:backdrop-blur-xl backdrop-saturate-150 bg-transparent transition-all duration-500 ease-in-out"
                style={{ "--navbar-height": "4rem" } as CSSProperties}
            >
                <header className="z-40 flex px-6 gap-4 w-full flex-row relative flex-nowrap items-center justify-between h-[var(--navbar-height)] max-w-[1536px]">
                    <div className="container mx-auto px-2 w-full">
                        <ul
                            className="flex gap-4 h-full flex-row flex-nowrap items-center data-[justify=start]:justify-start data-[justify=start]:flex-grow data-[justify=start]:basis-0 data-[justify=center]:justify-center data-[justify=end]:justify-end data-[justify=end]:flex-grow data-[justify=end]:basis-0"
                            data-justify="start"
                        >
                            <div onClick={scrollToMeetMeena} className="flex basis-0 flex-row flex-grow flex-nowrap justify-start bg-transparent items-center no-underline text-medium whitespace-nowrap box-border">
                                <div className="relative" style={{ opacity: 1, transform: "none" }}>
                                    <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary via-purple-500 to-secondary text-3xl tracking-tight hover:opacity-80 transition-all duration-300 cursor-pointer hover:scale-105 transform">
                                        MEENA
                                    </span>
                                </div>
                            </div>
                            <ul
                                className="h-full flex-row flex-nowrap items-center data-[justify=start]:justify-start data-[justify=start]:flex-grow data-[justify=start]:basis-0 data-[justify=center]:justify-center data-[justify=end]:justify-end data-[justify=end]:flex-grow data-[justify=end]:basis-0 hidden lg:flex gap-10"
                                data-justify="center"
                            >
                                <NavLink href="#story">Story</NavLink>
                                <NavLink href="#partnership">Partnership</NavLink>
                                <NavLink href="#gallery">Gallery</NavLink>
                                <NavLink href="#timeline">Timeline</NavLink>
                            </ul>
                            <ul
                                className="flex h-full flex-row flex-nowrap items-center data-[justify=start]:justify-start data-[justify=start]:flex-grow data-[justify=start]:basis-0 data-[justify=center]:justify-center data-[justify=end]:justify-end data-[justify=end]:flex-grow data-[justify=end]:basis-0 gap-2"
                                data-justify="end"
                            >
                                <SocialLink
                                    href={X_LINK}
                                    icon={
                                        <XIcon />
                                    }
                                />
                                <SocialLink
                                    href={TELEGRAM_LINK}
                                    icon={
                                        <TelegramIcon />
                                    }
                                />
                                <SocialLink
                                    href={DEXSCREENER_LINK}
                                    icon={
                                        <ChartIcon />
                                    }
                                />
                            </ul>
                        </ul>
                    </div>
                </header>
            </nav>
        </div>
    );
};
