import React, { FC, useEffect, useState } from "react";
import { Main } from "./components/Main";

export const AppContainer: FC = (props) => {
    const [widget, setWidget] = useState<any>(null);

    return (
        <>
            <Main />
        </>
    );
}
