import React from "react";
import { AppContainer } from "./AppContainer";
import { createRoot } from 'react-dom/client';

import './index.css';
import { BrowserRouter } from "react-router-dom";

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);
root.render(
        <BrowserRouter>
            <AppContainer />
        </BrowserRouter>
);

