import React, { FC } from "react";
import { MeetMeena } from "./MeetMeena";
import { Timeline } from "./Timeline";
import { HerStory } from "./HerStory";
import { PhotoGallery } from "./PhotoGallery";
import { JourneyTimeline } from "./JourneyTimeline";
import { JoinOurCommunity } from "./JoinOurCommunity";
import { Partnership } from "./Partnership";

export const Home: FC = () => {
    return (
        <div>
            <MeetMeena />
            <Timeline />
            <HerStory />
            <Partnership />
            <PhotoGallery />
            <JourneyTimeline />
            <JoinOurCommunity />
        </div>
    );
}
