import { FC } from "react";
import { TopBar } from "./TopBar";
import { Home } from "./Home";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import { TailwindDebug } from "./common/TailwindDebug";

export const Main: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const debug = searchParams.get("debug")
    const location = useLocation();
    console.log(location.pathname);

    return (
        <div className="fixed w-full z-50 bg-black h-full overflow-auto thin-scrollbar">
            <TopBar />
            {debug && <TailwindDebug />}
            <Routes>
                <Route>
                    <Route index element={<Home />} />
                </Route>
            </Routes>
        </div>
    );
}
