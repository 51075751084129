import { FC } from "react";
import { Header } from "./common/Header";
import { ChartIcon, DocIcon, FacebookIcon, TelegramIcon, XIcon } from "./common/Icons";
import { X_LINK, TELEGRAM_LINK, DEXSCREENER_LINK, DOCUMENTATION_LINK, FACEBOOK_LINK } from "./common/Config";




const SocialLink: FC<{ href: string; icon: JSX.Element; text: string }> = ({ href, icon, text }) => (
    <a className="relative tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-medium no-underline hover:opacity-80 active:opacity-disabled flex items-center gap-2 text-zinc-300 hover:text-primary transition-all hover:scale-105" href={href} target="_blank" rel="noopener noreferrer" tabIndex={0} role="link">
        {icon}
        <span>{text}</span>
    </a>
);


const FooterLink: FC<{ href: string, children: any }> = ({ href, children }) => (
    <a 
        className="relative inline-flex items-center tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 no-underline hover:opacity-80 active:opacity-disabled transition-opacity text-sm text-zinc-400 hover:text-primary"
        href={href} 
        target="_blank" 
        rel="noopener noreferrer" 
        tabIndex={0} 
        role="link"
    >
        {children}
    </a>
);

export const JoinOurCommunity: FC = () => {
    return (
        <footer className="py-8 relative">
            <div className="absolute inset-0 bg-gradient-to-t from-surface/80 to-background backdrop-blur-sm"></div>
            <div className="container mx-auto px-4 relative z-10">
                <div className="flex flex-col items-center justify-center gap-8">
                    <div className="text-center" style={{ opacity: 1, transform: "none" }}>
                        <Header title={"Join Our Community"} />
                        <p className="text-zinc-400 mt-2 text-lg">Be part of Meena's incredible journey</p>
                    </div>

                    <div className="flex flex-col md:flex-row items-center gap-8">
                        <SocialLink
                            href={X_LINK}
                            icon={<XIcon />}
                            text="X"
                        />
                        <SocialLink
                            href={TELEGRAM_LINK}
                            icon={<TelegramIcon />}
                            text="Telegram"
                        />
                        <SocialLink
                            href={DEXSCREENER_LINK}
                            icon={<ChartIcon />}
                            text="DexScreener"
                        />
                        <SocialLink
                            href={DOCUMENTATION_LINK}
                            icon={<DocIcon />}
                            text="Documentation"
                        />
                        <SocialLink
                            href={FACEBOOK_LINK}
                            icon={<FacebookIcon />}
                            text="Facebook"
                        />
                    </div>
                    <div className="flex items-center gap-4">
                        <FooterLink href="/">© 2024 Meena™</FooterLink>
                        <span className="text-zinc-600">•</span>
                        <FooterLink href="/privacy">All rights reserved.</FooterLink>
                    </div>
                </div>
            </div>
        </footer>
    );
};
