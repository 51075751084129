import { FC } from "react";

export const TailwindDebug: FC = () => {
  return (
    <span className="text-xs text-black ml-2 mt-2 tailwind-breaks absolute">
        <span className="inline-block sm:hidden">XS</span>
        <span className="hidden sm:inline-block md:hidden">SM</span>
        <span className="hidden md:inline-block lg:hidden">MD</span>
        <span className="hidden lg:inline-block xl:hidden">LG</span>
        <span className="hidden xl:inline-block">XL</span>
      </span>
  );
};