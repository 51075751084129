import { FC } from "react";

const TimelineBox: FC<{ icon: string; title: string; description: string }> = ({ icon, title, description }) => {
    return (
        <div
            className="flex flex-col relative overflow-hidden h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 rounded-large transition-transform-background motion-reduce:transition-none bg-zinc-900/80 backdrop-blur-xl border border-zinc-800 p-6 hover:scale-105 transition-all duration-300 shadow-lg"
            tabIndex={-1}
        >
            <div className="flex items-center gap-4">
                <div className="p-3 rounded-full bg-primary/10 backdrop-blur-xl">
                    <span className="text-2xl">{icon}</span>
                </div>
                <div>
                    <p className="text-zinc-400 font-medium tracking-wide text-sm">{title}</p>
                    <p className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}

export const Timeline: FC = () => {
    return (
        <section className="py-20 relative">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-secondary/5 to-transparent"></div>
            <div className="container mx-auto px-4 relative z-10">
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <TimelineBox icon="📅" title="Rescue Date" description="March 2023" />
                    <TimelineBox icon="🌱" title="Current Age" description="~2 Years" />
                    <TimelineBox icon="📍" title="Present Location" description="Thai Elephant Conservation Center" />
                    <TimelineBox icon="💚" title="Current Health Status" description="Excellent (Fully Recovered)" />
                </div>
            </div>
        </section>
    );
}