import { createRef, FC, useState } from "react";
import { Header } from "./common/Header";
import { ImageCard } from "./HerStory";
import CopyToClipboard from "react-copy-to-clipboard";

interface TextBlockProps {
    text: string;
}

const TextBlock: FC<TextBlockProps> = ({ text }) => (
    <p className="text-zinc-300 leading-relaxed">
        {text}
    </p>
);

const Separator: FC = () => (
    <hr
        className="shrink-0 border-none w-full h-divider bg-zinc-800"
        role="separator"
    />
)

const ANCHOR = createRef();

const ADDRESS = "D3DXcxnrsC4U4mMokcXDrXAojBWugi8VRuMvWXNaFMNt";

export const scrollToPartnership = () => {
    (ANCHOR.current as any)?.scrollIntoView({ behavior: "instant" });
}

export const Partnership: FC = () => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500); // Reset after 1.5 seconds
    };

    const formatAddress = (address: string) => {
        if (address.length <= 8) return address;
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    return (
        <section
            ref={ANCHOR as React.RefObject<HTMLDivElement>}
            id="partnership"
            className="py-20 relative"
        >
            <div className="absolute top-0 right-0 w-96 h-96 bg-secondary/10 rounded-full blur-3xl opacity-60"></div>
            <div className="container mx-auto px-4">
                <div className="text-center mb-4 md:mb-12 text-zinc-400 text-lg">
                    <Header title="Thai Elephant Conservation Center Partnership" />
                    <p className="">
                        The Meena on Solana Communitity has partnered with the Thai Elephant Conservation Center - the home of Meena.
                    </p>
                    <p>
                        Check out their website at <a className="underline decoration-sky-500 hover:decoration-purple-500" href="https://www.thailandelephant.org/" target="_blank" rel="noreferrer">https://www.thailandelephant.org/</a>
                    </p>
                    <p>
                        Check out their X post on the partnership <a className="underline decoration-sky-500 hover:decoration-purple-500" href="https://x.com/elephant_center/status/1865814576828412048" target="_blank" rel="noreferrer">here</a>
                    </p>
                </div>
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                    <div
                        className="flex flex-col relative overflow-hidden h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium rounded-large transition-transform-background motion-reduce:transition-none backdrop-blur-xl p-8"
                        tabIndex={-1}
                    >
                        <div className="space-y-6 text-center">
                            <p className="text-zinc-400 max-w-3xl mx-auto text-lg">
                                You can donate $MEENA tokens directly to the Thai Elephant Conservation Center by sending them to the following address:
                            </p>
                            <div className="flex flex-col justify-center text-zinc-400 text-lg mt-2">
                                <CopyToClipboard text={ADDRESS} onCopy={handleCopy}>
                                    <button
                                        className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-6 min-w-24 h-12 text-medium gap-3 rounded-full [&>svg]:max-w-[theme(spacing.8)] transition-transform-colors-opacity motion-reduce:transition-none bg-default data-[hover=true]:opacity-hover bg-gradient-to-r from-primary to-secondary text-black font-semibold hover:scale-105 transition-transform"
                                    >
                                        {!isCopied && <>Click to copy - {formatAddress(ADDRESS)}</>}
                                        {isCopied && <>Copied!</>}
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-6">
                        <div className="space-y-6">
                            <ImageCard alt="Meena's early days" src="/images/meenabucketwater.jpg" height="h-96 lg:h-52 xl:h-72" />
                        </div>
                        <div className="space-y-6">
                            <ImageCard alt="Meena's early days" src="/images/meenasymbol.jpg" height="h-96 lg:h-52 xl:h-72" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}