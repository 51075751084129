import { createRef, FC } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToStory } from "./HerStory";
import { scrollToPartnership } from "./Partnership";

const ANCHOR = createRef();

export const scrollToMeetMeena = () => {
    (ANCHOR.current as any)?.scrollIntoView({ behavior: "instant" });
}

export const MeetMeena: FC = () => {
    const navigate = useNavigate();
    
    return (
        <div ref={ANCHOR as React.RefObject<HTMLDivElement>} className="relative min-h-[90vh] flex items-center px-4 pt-20">
            <div 
                className="absolute top-20 right-20 w-72 h-72 bg-secondary/20 rounded-full blur-3xl" 
                style={{ opacity: 0 }}
            ></div>
            <div 
                className="absolute bottom-20 left-20 w-72 h-72 bg-primary/20 rounded-full blur-3xl" 
                style={{ opacity: 0 }}
            ></div>
            <div 
                className="container mx-auto grid lg:grid-cols-2 gap-12 items-center relative z-10" 
                style={{ opacity: 1 }}
            >
                <div className="space-y-8">
                    <div style={{ opacity: 1, transform: "none" }}>
                        <h1 className="text-5xl lg:text-7xl font-bold text-white">
                            Meet <span className="gradient-text">Meena</span>
                            <br />
                            <span className="text-3xl lg:text-4xl text-zinc-200 mt-2 block">
                                Thailand's Rescued & Beloved Baby Elephant
                            </span>
                        </h1>
                    </div>
                    <p 
                        className="text-lg text-zinc-300 max-w-xl" 
                        style={{ opacity: 1, transform: "none" }}
                    >
                        From a frightened calf found alone in a pond to a thriving symbol of hope, Meena's journey embodies resilience and the power of care. 
                        With $MEENA, you're not just investing in cryptocurrency - you're fueling a movement to protect her, support conservation, and safeguard the future of wildlife. 
                        Join the $MEENA community today and help create a lasting impact for elephants and the wild places they call home.
                    </p>
                    <div 
                        className="flex gap-4" 
                        style={{ opacity: 1, transform: "none" }}
                    >
                        <button 
                            className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-6 min-w-24 h-12 text-medium gap-3 rounded-full [&>svg]:max-w-[theme(spacing.8)] transition-transform-colors-opacity motion-reduce:transition-none bg-default data-[hover=true]:opacity-hover bg-gradient-to-r from-primary to-secondary text-black font-semibold hover:scale-105 transition-transform" 
                            type="button"
                            onClick={scrollToStory}
                        >
                            Explore Her Story
                        </button>
                        <button 
                            className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-6 min-w-24 h-12 text-medium gap-3 rounded-full [&>svg]:max-w-[theme(spacing.8)] transition-transform-colors-opacity motion-reduce:transition-none bg-default data-[hover=true]:opacity-hover bg-gradient-to-r from-primary to-secondary text-black font-semibold hover:scale-105 transition-transform" 
                            type="button"
                            onClick={scrollToPartnership}
                        >
                            Partnership Details
                        </button>
                    </div>
                    <div style={{ opacity: 1, transform: "none" }}></div>
                </div>
                <div 
                    className="relative h-[600px] rounded-3xl overflow-hidden bg-zinc-900/80 backdrop-blur-lg border border-zinc-800 p-2" 
                    style={{ opacity: 1, transform: "none" }}
                >
                    <img 
                        alt="Meena the elephant" 
                        decoding="async" 
                        data-nimg="fill" 
                        className="object-cover object-[50%_50%] scale-200 rounded-2xl" 
                        sizes="100vw" 
                        src="/images/meena5.jpg" 
                        style={{ position: "absolute", height: "100%", width: "100%", inset: 0, color: "transparent" }}
                    />
                    <div 
                        className="absolute bottom-4 left-4 right-4" 
                        style={{ opacity: 1, transform: "none" }}
                    >
                        <div 
                            className="flex flex-col relative overflow-hidden h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium rounded-large transition-transform-background motion-reduce:transition-none bg-zinc-900/90 backdrop-blur-xl border border-zinc-800 p-4" 
                            tabIndex={-1}
                        >
                            <p className="text-zinc-300">
                                "Meena's adorable eyes and charming personality have captured hearts across Thailand"
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}