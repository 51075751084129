import { createRef, FC } from "react";
import { Header } from "./common/Header";

const storyText1 = "In March 2023, a young elephant calf named Pang Mina was discovered alone in a villager's pond near the majestic Dong Phayayen-Khao Yai Forest Complex. This serendipitous discovery would mark the beginning of an extraordinary journey.";
const storyText2 = "At Wildlife Rescue Center 2, Meena's resilient spirit shone through as she formed an unforgettable bond with fellow calf Nong Tula. Though their time together was brief, it shaped Meena's remarkable character.";
const storyText3 = "Now flourishing at the National Elephant Institute, Meena continues to inspire with her vibrant personality and heartwarming presence, becoming a symbol of hope and perseverance.";


export interface ImageCardProps {
    alt: string;
    src: string;
    height: string;
}

export const ImageCard: FC<ImageCardProps> = ({ alt, src, height }) => (
    <div
        className={`flex flex-col overflow-hidden text-foreground outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium rounded-large transition-transform-background motion-reduce:transition-none backdrop-blur-xl p-2 ${height} relative hover:scale-105 transition-transform duration-300`}
        tabIndex={-1}
    >
        <img
            alt={alt}
            loading="lazy"
            decoding="async"
            data-nimg="fill"
            className="object-cover rounded-lg ml-auto mr-auto"
            sizes="100vw"
            src={src}
            style={{ position: 'absolute', height: '100%', inset: '0px', color: 'transparent' }}
        />
    </div>
);


interface TextBlockProps {
    text: string;
}

const TextBlock: FC<TextBlockProps> = ({ text }) => (
    <p className="text-zinc-300 leading-relaxed">
        {text}
    </p>
);

const Separator: FC = () => (
    <hr
        className="shrink-0 border-none w-full h-divider bg-zinc-800"
        role="separator"
    />
)

const ANCHOR = createRef();

export const scrollToStory = () => {
    (ANCHOR.current as any)?.scrollIntoView({ behavior: "instant" });
}

export const HerStory: FC = () => {
    return (
        <section
            ref={ANCHOR as React.RefObject<HTMLDivElement>}
            id="story"
            className="py-20 relative"
        >
            <div className="absolute top-0 right-0 w-96 h-96 bg-secondary/10 rounded-full blur-3xl opacity-60"></div>
            <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                    <Header title="Her Story" />
                    <p className="text-zinc-400 max-w-2xl mx-auto text-lg">
                        A tale of resilience and hope - follow Meena's transformation from a lost calf to Thailand's most cherished elephant
                    </p>
                </div>
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                    <div
                        className="flex flex-col relative overflow-hidden h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium rounded-large transition-transform-background motion-reduce:transition-none bg-zinc-900/80 backdrop-blur-xl border border-zinc-800 p-8"
                        tabIndex={-1}
                    >
                        <div className="space-y-6">
                            <TextBlock text={storyText1} />
                            <Separator />
                            <TextBlock text={storyText2} />
                            <Separator />
                            <TextBlock text={storyText3} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                        <div className="space-y-6">
                            <ImageCard alt="Meena's early days" src="/images/meena2.jpg" height="h-48" />
                            <ImageCard alt="Meena playing" src="images/meena3.png" height="h-64" />
                        </div>
                        <div className="space-y-6 pt-12">
                            <ImageCard alt="Meena with caretakers" src="/images/meena4.png" height="h-64" />
                            <ImageCard alt="Meena today" src="/images/meena.jpg" height="h-48" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};